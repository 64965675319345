<template>
  <div class="lg:flex lg:space-x-10">
    <div class="w-full lg:px-20 space-y-7">
      <div class="card p-3">
        <div v-if="discussions.data && discussions.data.length">
          <table class="w-full">
            <tbody>
            <discussion-item-box :discussion="discussion" :index="index + 1"
                                 v-for="(discussion, index) in discussions.data" :key="'discussion' + index"/>
            </tbody>
          </table>
          <div class="mt-4">
            <v-pagination
                class="justify-content-end"
                v-if="discussions.paginatorInfo.lastPage > 1"
                v-model="discussions.paginatorInfo.currentPage"
                :pages="discussions.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadDiscussions"
            />
          </div>
        </div>
        <div v-else>
          <p>Bạn chưa tham gia chủ đề nào</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import DiscussionItemBox from "./DiscussionItemBox";
import {changePageTitle} from "../../core/services/utils.service";

export default {
  name: "MyDiscussions",
  components: {
    DiscussionItemBox
  },
  data() {
    return {
      discussions: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadDiscussions() {
      let query = `query($page: Int) {
        me {
          discussions(first:5, page: $page, orderBy: [{column: "id", order:DESC}], where: {AND: [{column: "status", value: 1}]}) {
            data {
              id
              title
              slug
              views
              created_at
              comment_count
              thumbnail {
                url
              }
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }`;

      ApiService.graphql(query, {page: this.discussions.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.me && data.data.me.discussions) {
              this.discussions = data.data.me.discussions;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.loadDiscussions();
    changePageTitle("Chủ đề của tôi");
  }
}
</script>
